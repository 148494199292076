<template>
    <div>
        <el-card style="margin-top: 8px">
            <div slot="header" class="card-header">
                <span>采购统计</span>
            </div>

            <!-- 查看采购汇总-->
            <el-card style="margin-top: 20px">
                <!--标题-->
                <el-row class="title" :gutter="100" type="flex">
                    <el-col :span="4" class="content1">
                        <el-button
                            type="text"
                            @click="jumpToPurchaseInOutSummary"
                            class="font1"
                            :disabled="!hasOpenPurchaseInOutSummaryPrivilege"
                        >
                            查看采购汇总
                        </el-button>
                    </el-col>
                    <el-col :span="20" class="content2 font2">
                        <el-tooltip
                            class="font2"
                            style="text-decoration: underline"
                            :content="roleNamesForOpenPurchaseInOutSummary"
                            placement="top"
                        >
                            <span>此类角色</span>
                        </el-tooltip>
                        可查看采购汇总
                    </el-col>
                </el-row>
                <!--步骤-->
                <el-row :gutter="100" type="flex" style="height: 50px; margin-top: 8px">
                    <el-col :span="4" class="content2" style="text-decoration: underline">
                        <el-button
                            type="text"
                            class="font2"
                            @click="jumpToPurchaseInOutSummary"
                            :disabled="!hasOpenPurchaseInOutSummaryPrivilege"
                            >①查看采购汇总</el-button
                        >
                    </el-col>
                    <el-col :span="20" class="content2 font3">
                        <span> 选择机构名称-点击查询（如需要缩小搜索范围可以选择是否结账/日期等） </span>
                    </el-col>
                </el-row>
            </el-card>

            <!--查看采购明细-->
            <el-card style="margin-top: 20px">
                <!--标题-->
                <el-row class="title" :gutter="100" type="flex">
                    <el-col :span="4" class="content1">
                        <el-button
                            type="text"
                            @click="jumpToPurchaseInOutDetail"
                            class="font1"
                            :disabled="!hasOpenPurchaseInOutDetailPrivilege"
                            >查看采购明细</el-button
                        >
                    </el-col>
                    <el-col :span="20" class="content2 font2">
                        <span>
                            <el-tooltip
                                class="font2"
                                style="text-decoration: underline"
                                :content="roleNamesForOpenPurchaseInOutDetail"
                                placement="top"
                            >
                                <span>此类角色</span>
                            </el-tooltip>
                            <span>可查看采购明细</span>
                        </span>
                    </el-col>
                </el-row>
                <!--步骤-->
                <el-row :gutter="100" type="flex" style="height: 50px; margin-top: 8px">
                    <el-col :span="4" class="content2" style="text-decoration: underline">
                        <el-button
                            type="text"
                            class="font2"
                            @click="jumpToPurchaseInOutDetail"
                            :disabled="!hasOpenPurchaseInOutDetailPrivilege"
                            >①查看采购明细</el-button
                        >
                    </el-col>
                    <el-col :span="20" class="content2 font3">
                        <span>选择机构名称-点击查询（如需要缩小搜索范围可以选择业务类型/是否结账/日期等）</span>
                    </el-col>
                </el-row>
            </el-card>
        </el-card>
    </div>
</template>

<script>
import Util from 'js/Util';

const purchaseInOutSummaryPrivilegeFlag = 'menu.report.purchase.inOutSummary';
const openPurchaseInOutSummaryPrivilegeFlag = `${purchaseInOutSummaryPrivilegeFlag}.open`;

const purchaseInOutDetailPrivilegeFlag = 'menu.report.purchase.inOutDetail';
const openPurchaseInOutDetailPrivilegeFlag = `${purchaseInOutDetailPrivilegeFlag}.open`;

export default {
    name: 'PurchaseReportGuide',
    data() {
        return {
            rolesForOpenPurchaseInOutSummary: [],
            rolesForOpenPurchaseInOutDetail: [],
        };
    },
    created() {
        //查询“能够查询采购出入库汇总”的角色
        this.$efApi.roleApi.queryRolesByPrivilegeFlag(openPurchaseInOutSummaryPrivilegeFlag).then((rst) => {
            this.rolesForOpenPurchaseInOutSummary = rst;
        });
        //查询“能够查询采购出入库明细”的角色
        this.$efApi.roleApi.queryRolesByPrivilegeFlag(openPurchaseInOutDetailPrivilegeFlag).then((rst) => {
            this.rolesForOpenPurchaseInOutDetail = rst;
        });
    },
    computed: {
        roleNamesForOpenPurchaseInOutSummary() {
            return this.rolesForOpenPurchaseInOutSummary.map((e) => e.name).join('，');
        },
        roleNamesForOpenPurchaseInOutDetail() {
            return this.rolesForOpenPurchaseInOutDetail.map((e) => e.name).join('，');
        },
    },

    methods: {
        jumpToPurchaseInOutSummary() {
            Util.nameJump(this, purchaseInOutSummaryPrivilegeFlag);
        },
        jumpToPurchaseInOutDetail() {
            Util.nameJump(this, purchaseInOutDetailPrivilegeFlag);
        },

        hasOpenPurchaseInOutSummaryPrivilege() {
            return this.hasPrivilege(openPurchaseInOutSummaryPrivilegeFlag);
        },
        hasOpenPurchaseInOutDetailPrivilege() {
            return this.hasPrivilege(openPurchaseInOutDetailPrivilegeFlag);
        },
    },
};
</script>

<style scoped>
.font1 {
    font-size: 16px;
    font-family: PingFang SC, sans-serif;
    font-weight: bold;
    text-decoration: underline;
    color: #36bf5d;
    line-height: 28px;
}

.font2 {
    font-size: 16px;
    font-family: PingFang SC, sans-serif;
    font-weight: 400;
    color: #606266;
    line-height: 20px;
}

.font3 {
    font-size: 16px;
    font-family: PingFang SC, sans-serif;
    font-weight: 400;
    color: #9ca1ab;
    line-height: 20px;
}
.title {
    background: #d6ffe2;
    border-radius: 4px 4px 0 0;
}

.content1 {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.content2 {
    height: 100%;
    display: flex;
    justify-content: left;
    align-items: center;
}

.el-row {
    height: 70px;
}

.el-button.is-disabled,
.el-button.is-disabled:focus,
.el-button.is-disabled:hover {
    color: #c0c4cc;
    cursor: not-allowed;
}

/deep/ .el-card__body {
    padding-top: 0;
}

.card-header {
    font-size: 20px;
    font-family: PingFang SC, sans-serif;
    font-weight: 400;
    color: #212121;
    line-height: 28px;
}
</style>
